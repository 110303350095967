<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from '@/helpers/errorHandler';
import Uploader from "@/components/Homeworks/uploader";
/**
 * Starter component
 */
export default {
  page: {
    title: `Question Form`,
  },
  data() {
    return {
      title: "Questions",
      baseUrl: process.env.VUE_APP_BASE_URL,
      choice: null,
      showUploader: null,
      answerOptions:[
        {
          text:'First answer',
          value: 'ans_one'
        },
        {
          text:'Second answer',
          value: 'ans_two'
        },
        {
          text:'Third answer',
          value: 'ans_three'
        },
        {
          text:'Forth answer',
          value: 'ans_four'
        },
        {
          text:'Fifth answer',
          value: 'ans_five'
        },

      ],
      items: [
        {
          text: "Work center",
          href: "/",
        },
        {
          text: "Homeworks",
          href: "/homeworks",
        },
        {
          text: "Edits",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    Uploader
  },
  methods:{
    newChoice(){
      this.showUploader = false;
      this.$nextTick(()=>{
        this.showUploader = true;
      })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2>Upload Image Question</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="">
                    <div class="col-md-6" >
                      <b-form-group label="Choice:" label-for="input-C" >
                        <b-form-select v-model="choice" :options="this.answerOptions" @change="newChoice()"></b-form-select>
                      </b-form-group>
                    </div>
                    <Uploader v-if="showUploader" :url="`${baseUrl}quiz/add_ans_image?id=${this.$route.params.question}&ans_number=${choice}`" />
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="$router.push('/homeworks/'+$route.params.quiz+'/details')">Done</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>